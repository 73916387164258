import React from "react";
import "./Valeurs.css";
import GImage from "../Picture/GImage";

function Valeurs({ presentation }) {
  // <GBackground
  //   backgroundColor="#E9E6EA"
  //   src={presentation.illustration_valeurs.imageFile}
  //   className="valeurs bg-gray-200 px-3 sm:px-6 pt-24 pb-32 flex items-center"
  // >
  return presentation && presentation.descriptions_valeurs ? (
    <div className="relative valeurs bg-gray-200 px-3 sm:px-6 pt-24 pb-32 flex items-center">
      <GImage
        className="h-full !absolute inset-0 z-0"
        objectFit="cover"
        objectPosition="50% 67%"
        // imgCassName="bg-cover bg-bottom"
        src={presentation.illustration_valeurs.imageFile}
        alt="Valeurs du secteur langues du Groupe Français d'éducation Nouvelle"
      />
      <div className="relative max-w-6xl mx-auto w-auto">
        <h2 className="text-center w-auto p-1 sm:p-2 mb-6 text-gray-600">
          {presentation.title_valeurs}
        </h2>
        {presentation.subtitle_valeurs && (
          <h3 className="text-center text-red-500 uppercase font-extrabold text-2xl w-auto p-2 mb-6">
            {presentation.subtitle_valeurs}
          </h3>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {presentation.descriptions_valeurs &&
            presentation.descriptions_valeurs.map((valeur, index) => (
              <div
                data-aos="fade-right"
                data-aos-delay={parseFloat(index + 1) * 100}
                key={index}
                className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm py-6 px-4 max-w-sm mx-auto shadow-sm rounded"
              >
                <h4 className="h3 text-xl text-gray-600 uppercase font-extrabold">
                  {valeur.titre}
                </h4>
                <p className="">{valeur.description}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

export default Valeurs;
