import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { format } from "date-fns";
import FormatRangeDate from "../../utils/FormatRangeDate.js";
import Modal from "../../utils/Modal/Modal";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Meetings from "./Meetings";

const ActivityCard = ({ item, colSpan, activityStyles }) => {
  const slugify = require("slugify");
  const [openModal, setOpenModal] = useState(false);
  const currentDate = format(new Date(), "yyyy-MM-dd");
  const animationControl = useAnimation();
  const boxVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, y: 10 },
  };
  const { ref, inView } = useInView();

  const handleStyle = (mod, item) => {
    var classItem = "";
    if (mod === "border" || mod === "bg") {
      if (
        (!item.date_to && item.date_from < currentDate) ||
        (item.date_to && item.date_to < currentDate)
      ) {
        classItem =
          item.type === "Réunion"
            ? activityStyles.archived_reunion[mod]
            : activityStyles.archived_evenement[mod];
      } else if (item.type === "Évènement") {
        classItem = activityStyles.evenement[mod];
      } else if (item.type === "Réunion") {
        classItem = activityStyles.reunion[mod];
      }
    }
    return classItem;
  };

  useEffect(() => {
    if (inView) {
      animationControl.start("visible");
    }
  }, [inView, animationControl]);

  var isMeetings = item.meetings && item.meetings.length > 1;
  var isDescription = item.description && item.description.length > 1;
  var isDocument = item.documents && item.documents.length > 0;
  return (
    <React.Fragment>
      <motion.div
        ref={ref}
        initial="hidden"
        variants={boxVariants}
        animate={animationControl}
        className={`${colSpan}`}
      >
        <div
          className={`${
            isMeetings || isDescription || isDocument ? "pb-20" : ""
          } relative text-center min-h-full max-w-xl mx-auto shadow-md rounded bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm w-full`}
        >
          <div
            className={`px-6 pt-8 pb-2 rounded-t max-w-2xl ${handleStyle(
              "bg",
              item
            )} ${handleStyle("border", item)}`}
          >
            <h5 className="mb-3 text-white font-bold text-lg uppercase">
              {!item.title ? item.type : item.title}
            </h5>
          </div>
          <div className="px-6 pt-4">
            <div className={`pt-2 pb-0`}>
              <p className="text-gray-600 text-xl font-bold mb-2">
                {/* <span className="material-icons-outlined text-gray-600 mr-2">
                            date_range
                          </span> */}
                <span className="">
                  {<FormatRangeDate from={item.date_from} to={item.date_to} />}
                </span>
              </p>
            </div>
            {item.location && (
              <div className="pt-1 pb-0">
                <p className="flex text-center justify-center items-start text-lg font-bold">
                  <span className="shrink">{item.location}</span>
                </p>
              </div>
            )}
            {item.intro && (
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: `${item.intro}`,
                }}
              />
            )}
            {isMeetings || isDescription || isDocument ? (
              <div className="absolute bottom-0 left-0 right-0 flex py-0 px-4 justify-between justify-items-end flex-1 items-center self-end w-full">
                {(isDescription || isDocument || isMeetings) && (
                  <Link
                    className="btn bg-white my-3 text-gray-600"
                    to={`/activites/${
                      item.title
                        ? slugify(`${item.title}-${item.date_from}`, {
                            lower: true,
                            strict: true,
                          })
                        : slugify(`${item.type}-${item.date_from}`, {
                            lower: true,
                            strict: true,
                          })
                    }`}
                  >
                    {/* <span className="material-icons-outlined text-gray-600 mr-2">
                          more_horiz
                        </span> */}
                    En savoir +
                  </Link>
                )}
                {isMeetings && (
                  <button
                    onClick={() => setOpenModal(true)}
                    className="btn bg-white my-3 text-gray-600"
                    to={`/activites/${
                      item.title
                        ? slugify(`${item.title}-${item.date_from}`, {
                            lower: true,
                            strict: true,
                          })
                        : slugify(`${item.type}-${item.date_from}`, {
                            lower: true,
                            strict: true,
                          })
                    }`}
                  >
                    <span className="material-icons-outlined text-gray-600 mr-2">
                      event
                    </span>
                    Calendrier
                  </button>
                )}
              </div>
            ) : (
              <div className="pb-8"></div>
            )}
          </div>
        </div>
      </motion.div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={item.title}
        titleAlign="center"
        className={"w-full max-w-4xl mx-auto"}
      >
        {item.intro && (
          <div
            className="text-editor"
            dangerouslySetInnerHTML={{
              __html: `${item.intro}`,
            }}
          />
        )}
        {item.meetings && <Meetings meetings={item.meetings} />}
      </Modal>
    </React.Fragment>
  );
};

export default ActivityCard;
