import React from "react";
import "./Domaines.css";
import GImage from "../Picture/GImage";

function Domaines({ presentation }) {
  // <GBackground
  //   backgroundColor="#E9E6EA"
  //   src={presentation.illustration_domaines.imageFile}
  //   className="domaines px-3 sm:px-6 pt-24 pb-32 flex items-center"
  // >
  return presentation && presentation.descriptions_domaines ? (
    <div className="relative domaines px-3 sm:px-6 pt-24 pb-32 flex items-center">
      <GImage
        className="h-full !absolute inset-0 z-0"
        objectFit="cover"
        objectPosition="50% 65%"
        // imgCassName="bg-cover bg-bottom"
        src={presentation.illustration_domaines.imageFile}
        alt="Domaines d'activité du secteur langues du Groupe Français d'éducation Nouvelle"
      />
      <div className="relative max-w-6xl mx-auto w-auto">
        {presentation.title_domaines && (
          <h2 className="text-center w-auto p-2 mb-12 font-bold text-white">
            {presentation.title_domaines}
          </h2>
        )}
        {presentation.intro_domaines && (
          <h3 className="text-center text-red-400 uppercase font-extrabold text-2xl w-auto p-2 mb-6">
            {presentation.intro_domaines}
          </h3>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
          {presentation.descriptions_domaines &&
            presentation.descriptions_domaines.map((domaine, index) => (
              <div
                data-aos="fade-right"
                data-aos-delay={parseFloat(index + 1) * 100}
                key={index}
                className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-sm py-6 px-4 max-w-sm mx-auto shadow-sm rounded"
              >
                {domaine.titre && (
                  <h4
                    dangerouslySetInnerHTML={{ __html: `${domaine.titre}` }}
                    className="h3 text-lg text-gray-600 uppercase font-extrabold"
                  />
                )}
                {/* <hr className="border-2 border-gray-300" /> */}
                {domaine.description && (
                  <p
                    className="my-6"
                    dangerouslySetInnerHTML={{
                      __html: `${domaine.description}`,
                    }}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

export default Domaines;
