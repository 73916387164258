import React from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const Meetings = ({ meetings }) => {
  return meetings ? (
    <div className="pt-3 grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center items-center">
      {meetings.map((meeting) => (
        <div className="text-center py-4 px-3 border-2 border-gray-600">
          <p className="text-lg sm:text-xl">
            {format(new Date(meeting.date), "dd MMMM Y", {
              locale: fr,
            })}
          </p>
        </div>
      ))}
    </div>
  ) : (
    ""
  );
};

export default Meetings;
