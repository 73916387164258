import React from "react";
import Layout from "../layout";
import Jumbotron from "../components/Jumbotron/Jumbotron";
import Valeurs from "../components/Valeurs/Valeurs";
import config from "../../data/SiteConfig";
import Seo from "../components/Seo/Seo";
import Publications from "../components/Publications/Publications";
import Activities from "../components/Activities/Activities";
import Pratiques from "../components/Pratiques/Pratiques";
import Domaines from "../components/Domaines/Domaines";
import Contact from "../components/Contact/Contact";
import { graphql } from "gatsby";

const IndexPage = ({ data: gqlData }) => {
  const { directus } = gqlData;

  return (
    <Layout>
      <div className="index-container min-h-screen overflow-hidden">
        <Seo
          title={config.siteTitle}
          description={config.siteDescription}
          article={false}
        />
        {/*  Page illustration */}
        {/* <div
          className="relative mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div> */}
        {directus.homepage && <Jumbotron homepage={directus.homepage} />}
        {directus.settings && (
          <Activities
            title={directus.settings.activities_title}
            description={directus.settings.activities_description}
            evenements={directus.evenements}
            reunions={directus.reunions}
          />
        )}
        {directus.publications && directus.keywords && (
          <Publications
            title={directus.settings.publications_title}
            subtitle1="Rechercher par mot-clé"
            subtitle2="À la une"
            description={directus.settings.publications_description}
            publications={directus.publications}
            keywords={directus.keywords}
          />
        )}
        {directus.presentation && (
          <Valeurs presentation={directus.presentation} />
        )}
        {directus.categories && (
          <Pratiques
            title={directus.settings.practices_title}
            description={directus.settings.practices_description}
            categories={directus.categories}
          />
        )}
        {directus.presentation && (
          <Domaines presentation={directus.presentation} />
        )}
        {directus.contact && <Contact contact={directus.contact} />}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    directus: allDirectus {
      settings {
        id
        publications_title
        publications_description
        practices_title
        practices_description
        activities_title
        activities_description
        # activities_illustration {
        #   id
        #   imageFile {
        #     id
        #     childImageSharp {
        #       id
        #       gatsbyImageData(
        #         quality: 80
        #         layout: FULL_WIDTH
        #         placeholder: TRACED_SVG
        #         tracedSVGOptions: { color: "#d8b0b1" }
        #       )
        #     }
        #   }
        # }
      }
      homepage {
        title
        subtitle
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      evenements: activities(
        sort: ["-date_from", "-date_to"]
        limit: 4
        filter: { type: { _eq: "Évènement" }, date_to: { _gte: "now()" } }
      ) {
        id
        title
        type
        date_from
        date_to
        location
        intro
        description
        # documents {
        #   directus_files_id {
        #     id
        #     title
        #     filename_disk
        #     filename_download
        #     description
        #     imageFile {
        #       id
        #       extension
        #       publicURL
        #     }
        #   }
        # }
      }
      reunions: activities(
        sort: ["-date_from", "-date_to"]
        limit: 4
        filter: { type: { _eq: "Réunion" }, date_to: { _gte: "now()" } }
      ) {
        id
        title
        type
        date_from
        date_to
        meetings
        location
        intro
        description
        # documents {
        #   directus_files_id {
        #     id
        #   }
        # }
      }
      keywords(filter: { publications: { id: { _nnull: true } } }) {
        id
        name
        publications {
          id
        }
      }
      publications(limit: 3, sort: "-sort") {
        id
        title
        intro
        type
      }
      categories(sort: "sort", limit: 8) {
        id
        title
      }
      presentation {
        title_valeurs
        subtitle_valeurs
        descriptions_valeurs
        illustration_valeurs {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
        title_domaines
        intro_domaines
        descriptions_domaines
        illustration_domaines {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
      contact {
        title
        subtitle
        intro
        informations
        illustration {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: FULL_WIDTH
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
      }
    }
  }
`;
