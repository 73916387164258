import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import ActivityCard from "./ActivityCard";
import "./Activities.css";

const initColSpan = "col-span-1";

const Activities = ({ evenements, reunions, page, title, description }) => {
  const [type, setType] = useState("Évènement");
  const [colSpan, setColSpan] = useState(initColSpan);
  const [activities, setActivities] = useState(null);
  const evenementsLength = evenements ? evenements.length : 0;
  const reunionsLength = reunions ? reunions.length : 0;

  useEffect(() => {
    if (localStorage.getItem("activity_type")) {
      setType(localStorage.getItem("activity_type"));
    }
    if (evenements || reunions) {
      setActivities([].concat(reunions).concat(evenements));
    }
    if (type === "Évènement" && evenementsLength === 1) {
      setColSpan("col-span-2");
    } else if (type === "Réunion" && reunionsLength === 1) {
      setColSpan("col-span-2");
    } else {
      setColSpan("col-span-1");
    }
  }, [type, evenements, reunions, evenementsLength, reunionsLength]);

  const activityStyles = {
    reunion: {
      border: "border-gray-900",
      bg: "bg-gray-900",
    },
    evenement: {
      border: "border-red-500",
      bg: "bg-red-500",
    },
    archived_reunion: {
      border: "border-gray-500",
      bg: "bg-gray-500",
    },
    archived_evenement: {
      border: "border-red-200",
      bg: "bg-red-200",
    },
  };

  const changeType = (newType) => {
    // var newType = type === "Évènement" ? "Réunion" : "Évènement";
    localStorage.setItem("activity_type", newType);
    setType(newType);
  };

  return (
    <div className="relative activities bg-gray-300 bg-opacity-50 backdrop-filter backdrop-blur-sm pt-12 pb-20 px-3 sm:px-6 flex items-center">
      <div
        className={`mx-auto max-w-6xl w-full ${page === true && "pt-8 pb-32"}`}
      >
        {title ? (
          <div className="px-6">
            {page === true ? (
              <React.Fragment>
                <h1 className="text-center mt-6 mb-8">{title}</h1>
                <hr
                  className={`border-none h-2 ${
                    type === "Réunion"
                      ? activityStyles.reunion.bg
                      : activityStyles.evenement.bg
                  }`}
                />
              </React.Fragment>
            ) : (
              <h3 className="text-center w-auto pt-8 mb-8">
                {title} en cours ou à venir
              </h3>
            )}
          </div>
        ) : (
          <div className="px-6">
            <h3 className="text-center w-auto text-gray-600 pt-8 mb-16">
              Autres activités
            </h3>
          </div>
        )}
        {description && (
          <div className="text-center max-w-3xl mx-auto mt-6 mb-12 px-3 sm:px-6">
            <p className="text-lg text-gray-600">{description}</p>
          </div>
        )}
        <div className="mb-10 grid grid-cols-2 gap-4 md:gap-12 w-full px-3 sm:px-6">
          <div className="place-self-end">
            <button
              className={`btn transform text-white ${
                type === "Évènement"
                  ? `${activityStyles.evenement.bg} scale-110`
                  : `bg-gray-600 hover:${activityStyles.evenement.bg} scale-100`
              }`}
              onClick={() => changeType("Évènement")}
            >
              Évènement{evenementsLength > 1 && "s"}
            </button>
          </div>
          <div className="place-self-start">
            <button
              className={`btn transform text-white ${
                type === "Réunion"
                  ? `${activityStyles.reunion.bg} scale-110`
                  : `bg-gray-600 hover:${activityStyles.reunion.bg} scale-100`
              }`}
              onClick={() => changeType("Réunion")}
            >
              Réunion{reunionsLength > 1 && "s"}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12 w-full px-3 sm:px-6">
          {activities && (evenementsLength > 0 || reunionsLength > 0) ? (
            <React.Fragment>
              {activities
                .filter((item) => {
                  return item.type === type;
                })
                .map((item) => {
                  return (
                    <ActivityCard
                      key={item.id}
                      colSpan={colSpan}
                      activityStyles={activityStyles}
                      item={item}
                    />
                  );
                })}
              {type === "Évènement" && evenementsLength === 0 ? (
                <div className="col-span-2">
                  <p className="text-center">Aucun évènement à venir</p>
                </div>
              ) : (
                ""
              )}
              {type === "Réunion" && reunionsLength === 0 ? (
                <div className="col-span-2">
                  <p className="text-center">Aucune réunion à venir</p>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            <div className="col-span-2">
              <p className="text-center">Aucune activité à venir</p>
            </div>
          )}
        </div>
        {page !== true && (
          <div className="text-center mt-12">
            <Link
              className={`btn text-white font-bold ${
                type === "Réunion"
                  ? activityStyles.reunion.bg
                  : activityStyles.evenement.bg
              }`}
              to={`/activites`}
            >
              <span className="material-icons-outlined text-white mr-3">
                list
              </span>
              {type === "Évènement"
                ? "Tous les évènements"
                : "Toutes les réunions"}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activities;
