import React from "react";
import { Link, navigate } from "gatsby";
import "./Pratiques.css";

function Pratiques({ categories, page, title, description }) {
  const slugify = require("slugify");
  return (
    <div className="pratiques max-w-6xl mx-auto px-3 sm:px-6 pt-8 pb-32 flex items-center">
      <div className="mx-auto w-auto">
        {title && (
          <div>
            {page === true ? (
              <h1 className="text-center mt-6 mb-8">{title}</h1>
            ) : (
              <h3 className="text-center w-auto pt-8 mb-8">{title}</h3>
            )}
            <hr className="bg-red-500 border-none h-2" />
            {description && (
              <div className="text-center max-w-3xl mx-auto mt-6 mb-12">
                <p className="text-lg text-gray-600">{description}</p>
              </div>
            )}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12">
          {categories &&
            categories.map((category) => (
              <button
                data-aos="fade-down"
                data-aos-delay="150"
                key={category.id}
                className="relative bg-white shadow-lg hover:bg-gray-50 rounded-sm border-0 border-l-8 border-red-500 px-3 sm:px-4 py-3 text-center cursor-pointer flex justify-center"
                onClick={() => {
                  navigate(
                    `/pratiques/${slugify(category.title, {
                      lower: true,
                      strict: true,
                    })}`
                  );
                }}
              >
                <h5 className="text-gray-600 text-base self-center font-bold uppercase">
                  {category.title}
                </h5>
              </button>
            ))}
        </div>
        {page !== true && (
          <div className="text-center mt-12">
            <Link
              className="btn text-white font-bold uppercase bg-red-500 hover:bg-red-400"
              to="/pratiques"
            >
              <span className="material-icons-outlined mr-3">list</span>
              Liste des pratiques
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pratiques;
