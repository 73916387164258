import React from "react";
import "./Jumbotron.css";
import GImage from "../Picture/GImage";
import { Link } from "gatsby";

function Jumbotron({ homepage }) {
  return homepage ? (
    <div className="jumbotron relative px-3 sm:px-6 pt-6 pb-8">
      <div className="h-20 md:h-28"></div>
      {/* <div
        className="absolute w-full left-0 bottom-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <svg
          className="h-full w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#0099ff"
            fillOpacity="1"
            d="M0,224L80,213.3C160,203,320,181,480,181.3C640,181,800,203,960,192C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div> */}
      <div className="ripple-background">
        <div className="circle xxlarge shade1"></div>
        <div className="circle xlarge shade2"></div>
        <div className="circle large shade3"></div>
        <div className="circle mediun shade4"></div>
        <div className="circle small shade5"></div>
      </div>
      <div className="max-w-6xl min-h-50vh flex items-center mx-auto z-10">
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="my-6 lg:my-0 lg:col-span-2 self-center">
            <div className="inline-block px-4 py-4">
              <div dangerouslySetInnerHTML={{ __html: `${homepage.title}` }} />
              <Link
                className="text-2xl sm:text-3xl mb-4 font-bold inline-block mt-3 bg-red-500 hover:bg-red-400 text-white px-2 py-1 rounded"
                to="/presentation"
              >
                <strong>Secteur Langues</strong>
              </Link>
              <div
                dangerouslySetInnerHTML={{ __html: `${homepage.subtitle}` }}
              />
            </div>
          </div>
          <div className="my-6 lg:my-0 lg:col-span-1 self-center">
            {homepage.illustration && (
              <GImage
                data-aos="fade-right"
                data-aos-delay="150"
                objectFit="contain"
                className="block mb-4 lg:pb-0 w-full max-w-md"
                src={homepage.illustration.imageFile}
                alt="Illustration du secteur langues du Groupe Français d'éducation Nouvelle sur le mouvement de recherche et de formation en éducation"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

export default Jumbotron;
